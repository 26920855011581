import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

import ProfileBubble from "@/components/layout/ProfileBubble";
import { logger } from "@/lib/logger";
import { titleCase } from "@/lib/utils";
import type { ProfileSection } from "@/types";
import { ProfileSections } from "@/types";
import { AnchorHTMLAttributes } from "react";

type Props = {
  sectionItem: ProfileSection;
  showFeedback?: boolean;
};

export default function ProfileSectionView({ sectionItem, showFeedback }: Props) {
  const section = sectionItem.section;

  return (
    <ProfileBubble
      title={section == ProfileSections.WebLinks ? "Around the Web" : titleCase(section)}
    >
      <div className="overview markdown">
        <SectionSummary item={sectionItem} />
        <Bullets item={sectionItem} />
      </div>
      {/* <hr className="-mx-4 mb-4" />
      <div className="flex justify-between items-center text-xs">
        <div className="bg-gray-200 font-medium px-2 py-1 rounded flex items-center">
          <Square3Stack3DIcon className="h-4 w-4 inline-block mr-1" />
          Generated with AI
        </div>
        <div>
          <span
            className="text-blue-600 hover:text-blue-800 ml-2 cursor-pointer font-medium"
            onClick={() => entityStore.uiTab.set(TabId.Sources)}
          >
            How is this created?
          </span>
        </div>
      </div> */}
    </ProfileBubble>
  );
}

function Bullets({ item }: { item: ProfileSection }) {
  const bullets = item.data.bullets;
  if (!bullets) return null;

  const sources: string[] = [];

  return (
    <ul className="list-disc pl-4">
      {bullets.map((bullet, i) => (
        <li key={i}>
          <ReactMarkdown
            remarkPlugins={[remarkBreaks]}
            components={{
              a(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
                const { title, ...rest } = props;
                return (
                  <sup>
                    <a
                      {...rest}
                      target="_blank"
                      data-tooltip-id="tooltip"
                      data-tooltip-content={title}
                    />
                  </sup>
                );
              },
            }}
          >
            {bullet.text +
              (bullet.sources
                ?.map((url, j) => {
                  let idx = sources.indexOf(url) + 1;
                  if (idx == 0) idx = sources.push(url);
                  return `[${idx}](${url})`;
                })
                .join(" ") || "")}
          </ReactMarkdown>
        </li>
      ))}
    </ul>
  );
}

export function SectionSummary({ item }: { item: ProfileSection }) {
  // process ({ "citations" }) out of text
  const text = item.data.summary;
  if (!text) return null;
  const footnoted = convertToFootnotes(text);

  return (
    <ReactMarkdown
      remarkPlugins={[remarkBreaks]}
      components={{
        a(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
          const { title, ...rest } = props;
          return (
            <sup>
              <a {...rest} target="_blank" data-tooltip-id="tooltip" data-tooltip-content={title} />
            </sup>
          );
        },
      }}
    >
      {footnoted}
    </ReactMarkdown>
  );
}

function convertToFootnotes(markdown: string): string {
  const citationRegex = /\(?{"citation": "(.+?)"\}\)?,?/g;
  let match: RegExpExecArray | null;
  const footnotes: Record<string, number> = {};
  let footnoteIndex = 1;

  // Replace citations with footnote references
  markdown = markdown.replace(citationRegex, (match, url: string) => {
    if (!(url in footnotes)) {
      footnotes[url] = footnoteIndex++;
    }
    let title: string | undefined;
    try {
      title = new URL(url).hostname;
    } catch (e) {
      logger.info("footnotes url error", url, e);
    }
    return `[${footnotes[url]}](${url} "${title || "Source"}")`;
  });

  // Append footnotes at the end
  // const footnoteEntries = Object.entries(footnotes)
  //   .map(([url, index]) => `[^${index}]: ${url}`)
  //   .join("\n");

  return markdown; //  + "\n\n" + footnoteEntries;
}
