import ProfileBubble from "@/components/sections/ProfileBubble";
import { ExperienceLabels, GroupedExperiences } from "@/components/experiences/utils";
import { ProfilePageSection } from "@/types";
import ExpandoList from "@/components/ui/ExpandoList";
import { twJoin } from "tailwind-merge";
import GroupedExperienceRow from "@/components/experiences/GroupedExperienceRow";
import { useState } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useCanEdit } from "@/hooks/useUIStore";

interface Props {
  section: ProfilePageSection;
  allGroupedExperiences: GroupedExperiences[];
}

const isGridView = (section: string) => section == (ProfilePageSection.Investments as string);

export default function ExperienceSection({ section, allGroupedExperiences }: Props) {
  const [hovering, setHovering] = useState(false);
  const canEdit = useCanEdit();
  return (
    <div onMouseOver={() => setHovering(true)} onMouseOut={() => setHovering(false)}>
      <ProfileBubble
        title={ExperienceLabels[section]}
        key={section}
        section={section}
        className="flex flex-col"
        titleButtons={
          <>
            {canEdit && hovering && (
              <>
                <PlusIcon
                  className="flex-shrink-0 cursor-pointer hover:text-brand-600 h-6 w-6 text-gray-500"
                  onClick={() => {}}
                />
              </>
            )}
          </>
        }
      >
        <>
          {allGroupedExperiences.length === 0 && (
            <div className="text-center text-gray-500">No work history listed yet</div>
          )}
          {allGroupedExperiences.length > 0 && (
            <ExpandoList
              items={allGroupedExperiences}
              limit={!isGridView(section) ? 5 : 6}
              seeMoreClassName="pt-4 text-center"
              className={twJoin(
                "list-inside text-gray-700 gap-4 flex flex-col",
                isGridView(section) ? "sm:grid sm:grid-cols-2" : "",
              )}
              divideExpander
              renderItem={(groupedExperiences: GroupedExperiences, index) => (
                <div
                  key={
                    (groupedExperiences[0]?.toId || groupedExperiences[0]?.toName || "empty") +
                    index.toString()
                  }
                >
                  {index !== 0 && !isGridView(section) && <hr className="mb-2" />}
                  <GroupedExperienceRow groupedExperiences={groupedExperiences} section={section} />
                </div>
              )}
            />
          )}
        </>
      </ProfileBubble>
    </div>
  );
}
