import { AnchorHTMLAttributes } from "react";
import { GenericProfile } from "@/types";

// To be used with SourceTooltip. <SourceTooltip /> needs to be placed somewhere
// in the component tree for this to work.

export default function SourceTooltipLink({
  title,
  ...rest
}: AnchorHTMLAttributes<HTMLAnchorElement> & { title: string | undefined }) {
  return (
    <span
      className="text-xs border-0 inline-flex justify-center w-5 h-5 text-blue-700 rounded-full p-1 bg-gray-100 ml-1 font-semibold"
      data-tooltip-id="source-tooltip"
      data-tooltip-content={title}
    >
      <a {...rest}></a>
    </span>
  );
}

function getIndex(snapshot: GenericProfile, id: string): number {
  return (
    snapshot.references
      ?.filter((e) => !snapshot?.notableLinkIds?.includes(e.id))
      .map((e) => e.id)
      .indexOf(id) + 1
  );
}

export function getReferenceDataToShowFromCitationId(snapshot: GenericProfile, citationId: string) {
  const mref = snapshot?.references?.find((ref) => ref.id === citationId);
  if (!mref) return undefined;

  const idx = getIndex(snapshot, citationId);
  if (idx < 1) return undefined;

  const url = mref?.url.replace(/'/g, "\\'");
  return {
    idx,
    citationId,
    url,
    citationUrl: `#source${citationId}`,
  };
}
export function getReferenceDataToShowFromURL(snapshot: GenericProfile, url?: string) {
  if (!url) return undefined;
  const mref = snapshot?.references?.find((ref) => ref.url === url);

  // TODO: We should still show the reference somehow even if it's not included in the list of links.
  if (!mref) return undefined;

  const idx = getIndex(snapshot, mref.id);
  if (idx < 1) return undefined;
  return {
    idx,
    citationId: mref.id,
    url: url.replace(/'/g, "\\'"),
    citationUrl: `#source${mref.id}`,
  };
}
