import Modal, { ModalBody } from "@/components/modals/Modal";
import { classNames } from "@/lib/stringUtils";
import React, { useEffect, useState } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import SearchBox from "@/components/search/SearchBox";
import Button, { ButtonVariant } from "@/components/ui/Button";
import { AutocompleteEntity, EntityType } from "@/types";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectCompany: ({ id, name }: { id?: string; name?: string }) => void;
};

export default function ChangeCompanyModal({ open, setOpen, selectCompany }: Props) {
  const [cantFindCompany, setCantFindCompany] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (open) {
      setCantFindCompany(undefined);
    }
  }, [open]);

  return (
    <>
      <Modal open={open} setOpen={setOpen}>
        <ModalBody
          title={"Select an organization"}
          icon={<QuestionMarkCircleIcon className={classNames("w-6 h-6", "text-blue-600")} />}
          iconBg={"bg-blue-100"}
          className="sm:min-w-[500px] sm:min-h-[400px]"
        >
          <SearchBox
            initialQuery={""}
            submitButton
            className="max-w-72 lg:max-w-96"
            magnifyClassName="left-4"
            placeholder="Search for the organization"
            type={EntityType.Company}
            onSelect={(e: AutocompleteEntity) => {
              if (!e.id) {
                setCantFindCompany(e.name);
              } else {
                selectCompany({ id: e.id });
              }
            }}
          />
          {cantFindCompany && (
            <div className="text-sm mt-4">
              <Button
                variant={ButtonVariant.Small}
                className="mt-2"
                onClick={() => {
                  selectCompany({ name: cantFindCompany });
                }}
              >
                Set organization to&nbsp;<strong>{cantFindCompany}</strong>
              </Button>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}
