import { entityStore } from "@/stores/entityStore";
import { unwrapError } from "@/lib/miscUtils";
import WithLabel from "@/components/inputs/WithLabel";
import TextField from "@/components/inputs/TextField";
import TextArea from "@/components/inputs/TextArea";
import { useState } from "react";
import useSubmitButton from "@/components/hooks/useSubmitButton";
import {
  BaseRelationshipData,
  PersonCompanyRelationship,
  RelationshipDataEducatedAt,
  RelationshipDataInvestedIn,
  RelationshipDataVolunteeredAt,
  RelationshipDataWorkedAt,
  RelationshipType,
  RelationshipWithEntity,
} from "@/models/relationship/relationshipTypes";
import ComboBox, {
  ComboBoxItemType,
  makeComboBoxItemsFromEnum,
} from "@/components/inputs/ComboBox";
import { EmploymentType } from "@/types";
import Button, { ButtonVariant } from "@/components/ui/Button";

interface Props {
  relationship: Partial<RelationshipWithEntity> & { type: string };
  onFinishEdit?: () => void;
}

const UNSPECIFIED = "(Unspecified)";

export default function EditExperienceRow({ relationship, onFinishEdit }: Props) {
  const [error, setError] = useState<string>();
  const { SubmitButton, setSubmitting } = useSubmitButton();
  const baseData = relationship?.data as BaseRelationshipData;
  const workData = relationship?.data as RelationshipDataWorkedAt;
  const educationData = relationship?.data as RelationshipDataEducatedAt;
  const investmentData = relationship?.data as RelationshipDataInvestedIn;
  const volunteeringData = relationship?.data as RelationshipDataVolunteeredAt;
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const title = formData.get("title") as string | null;
    let type = formData.get("type") as string | null;
    if (type === UNSPECIFIED) type = null;
    const location = formData.get("location") as string | null;
    const startDate = formData.get("startDate") as string | null;
    const endDate = formData.get("endDate") as string | null;
    const description = formData.get("description") as string | null;
    const degree = formData.get("degree") as string | null;
    const role = formData.get("role") as string | null;

    try {
      setError(undefined);
      if (relationship?.id) {
        // Editing case
        await entityStore.updateRelationship({
          ...(relationship as { id: string }),
          startedDate: startDate,
          endedDate: endDate,
          data: {
            ...(relationship.data as object),
            location,
            description,
            degree,
            title,
            type,
            role,
          },
        });
      } else {
        // Adding case
        await entityStore.createRelationship({
          // Some fields need to be set by the parent component. The fields
          // responsible for the relationship type, as well as where the
          // relationship is pointing to.
          ...relationship,
          startedDate: startDate,
          endedDate: endDate,
          data: {
            location,
            description,
            title,
            degree,
            type,
            role,
          },
        });
      }
      onFinishEdit?.();
    } catch (e) {
      setError(unwrapError(e));
    } finally {
      setSubmitting(false);
    }
  };

  const employmentTypes: ComboBoxItemType[] = [
    { id: "", name: UNSPECIFIED },
    ...makeComboBoxItemsFromEnum(EmploymentType),
  ];

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <hr className="w-full mb-2" />
        <div className="grid grid-cols-3 gap-4">
          {relationship.type === PersonCompanyRelationship.WorkedAt && (
            <WithLabel label={"Title"}>
              <TextField name="title" placeholder="Title" defaultValue={workData?.title} />
            </WithLabel>
          )}
          {relationship.type === PersonCompanyRelationship.VolunteeredAt && (
            <WithLabel label={"Role"}>
              <TextField name="role" placeholder="Role" defaultValue={volunteeringData?.role} />
            </WithLabel>
          )}
          {relationship.type === PersonCompanyRelationship.EducatedAt && (
            <WithLabel label={"Deegree"}>
              <TextField name="degree" placeholder="Degree" defaultValue={educationData?.degree} />
            </WithLabel>
          )}
          {relationship.type === PersonCompanyRelationship.WorkedAt && (
            <WithLabel label={"Employment type"}>
              <ComboBox
                name="type"
                items={employmentTypes}
                value={
                  relationship?.id ?
                    employmentTypes.find((x) => x.name === workData?.type) || employmentTypes[0]
                  : undefined
                }
                onSelect={async (typeOrArray) => {
                  const type = Array.isArray(typeOrArray) ? typeOrArray[0] : typeOrArray;
                  try {
                    if (relationship?.id) {
                      await entityStore.updateRelationship({
                        ...(relationship as { id: string }),
                        data: {
                          ...(relationship.data as object),
                          type: type.id === "" ? "" : type.name,
                        },
                      });
                    }
                  } catch (e) {
                    setError(unwrapError(e));
                  }
                }}
              />
            </WithLabel>
          )}
          {(relationship.type === PersonCompanyRelationship.WorkedAt ||
            relationship.type === PersonCompanyRelationship.EducatedAt) && (
            <WithLabel label={"Location"}>
              <TextField
                name="location"
                type="text"
                placeholder="Location"
                defaultValue={workData?.location}
              />
            </WithLabel>
          )}
          <WithLabel label={"Start date"}>
            <TextField
              name="startDate"
              placeholder="Start date"
              defaultValue={relationship?.startedDate || ""}
            />
          </WithLabel>
          <WithLabel label={"End date"}>
            <TextField
              name="endDate"
              placeholder="End date (empty if ongoing)"
              defaultValue={relationship?.endedDate || ""}
            />
          </WithLabel>
        </div>
        <div className="mt-2">
          <WithLabel label={"Description"}>
            <TextArea rows={6} name="description" defaultValue={baseData?.description}></TextArea>
          </WithLabel>
        </div>
        {error && <div className="text-red-500 mt-2">{error}</div>}
        <div className="flex justify-center my-2">
          <SubmitButton className="mr-2">Save</SubmitButton>
          <Button variant={ButtonVariant.Secondary} onClick={() => onFinishEdit?.()}>
            Cancel
          </Button>
        </div>
        <hr className="w-full" />
      </form>
    </div>
  );
}
