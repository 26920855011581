import {
  calculateDateString,
  CompanyConfig,
  CompanyType,
  formatDate,
} from "@/components/experiences/utils";
import {
  getCompanyConfig,
  parseEmploymentTypeFromCompany,
} from "@/components/sections/WorkEducation";
import ConditionalLink from "@/components/ui/ConditionalLink";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { classNames, smartTruncate } from "@/lib/stringUtils";
import {
  BaseRelationshipData,
  RelationshipDataWorkedAt,
  RelationshipWithEntity,
} from "@/models/relationship/relationshipTypes";
import { Entity, EntityType } from "@/types";
import {
  AcademicCapIcon,
  BeakerIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  HandRaisedIcon,
} from "@heroicons/react/20/solid";
import { Relationship } from "@prisma/client";
import { ReactElement } from "react";
import moment from "moment";

interface Props {
  firstPosition: RelationshipWithEntity;
  lastPosition: RelationshipWithEntity;
  useFirstPositionDescription?: boolean;
  showDates: boolean;
  editMode: boolean;
  titleButtons?: ReactElement;
}

export default function CompanyHeader({
  firstPosition,
  lastPosition,
  useFirstPositionDescription,
  showDates,
  editMode,
  titleButtons,
}: Props) {
  const companyEntity = firstPosition.to;
  const companyConfig = getCompanyConfig({
    workExperience: firstPosition,
    companyType: parseEmploymentTypeFromCompany(firstPosition),
    companyEntity: companyEntity as Entity,
  });

  let description = companyConfig.description;
  if (useFirstPositionDescription) {
    const date = firstPosition.startedDate && formatDate(firstPosition.startedDate);
    // TODO: Originally title was included here, but investments don't have it
    const shortDescription = smartTruncate(
      (firstPosition.data as BaseRelationshipData)?.description || "",
      100,
    );
    description = [date, shortDescription].filter(Boolean).join(" · ");
  }

  // Keep in mind they usually come reverse chronologically.
  // TODO: Sort chronologically instead of relying on ordering?
  const companyDates = calculateDateString(lastPosition, firstPosition);

  return (
    <>
      <div className={classNames("flex items-center")}>
        <ConditionalLink
          href={companyEntity?.slug || undefined}
          data-tooltip-id="tooltip"
          data-tooltip-content={companyEntity ? `See ${companyEntity.name}'s profile` : undefined}
        >
          <EntityIconWithPlaceholder
            entity={{
              id: companyEntity?.id || firstPosition.id,
              name: companyEntity?.name || firstPosition.toName || firstPosition.type,
              imageUrl:
                companyEntity?.imageUrl && !companyEntity?.imageUrl?.includes("static.licdn.com") ?
                  companyEntity?.imageUrl
                : firstPosition.toImageUrl,
              fallbackImageUrl: firstPosition.toImageUrl,
              url: companyEntity?.url,
              type: EntityType.Company,
            }}
            className="h-10 w-10 mt-[2px] mr-4 border border-gray-100"
            customIcon={companyConfig.icon}
          />
        </ConditionalLink>
        <div>
          <ConditionalLink
            href={companyEntity?.slug || undefined}
            className="text-base font-semibold underline mr-2 cursor-pointer"
          >
            {companyConfig.name.replace("\\'", "'")}
          </ConditionalLink>

          <span className="text-xs text-gray-400">{showDates ? companyDates : ""}</span>
        </div>
        <div className="flex-grow"></div>
        <div className="flex-shrink-0">{titleButtons}</div>
      </div>
      {/* Unimplemented editMode && (
        <div className="flex pl-14 mb-4 gap-2">
          <Button variant={ButtonVariant.SmallSecondary}>Change organization</Button>
          <Button variant={ButtonVariant.SmallSecondary}>Reclassify</Button>
          <Button variant={ButtonVariant.SmallSecondary}>Remove</Button>
        </div>
      )*/}
      {description && (
        <div className=" sm:-mt-2">
          <div className="text-sm sm:ml-14">
            {editMode ? description : smartTruncate(description, 300)}
          </div>
          <div className="h-[8px]" />
        </div>
      )}
    </>
  );
}
