import moment from "moment";
import { Fragment, ReactNode } from "react";

import EditableFact from "@/components/entities/EditableFact";
import { useCanEdit } from "@/hooks/useUIStore";
import { ageFromBirthYear, prettyUrl } from "@/lib/utils";
import { PersonFact } from "@/models/facts/facts.types";
import { entityStore } from "@/stores/entityStore";
import { Attribute, EntityWithAttributes } from "@/types";
import { useStore } from "@nanostores/react";
import { Entity } from "@prisma/client";

type Props = {
  entity: Entity;
  attributes: Attribute[];
  skipLastUpdated?: boolean;
};

export default function PersonSubtitle(props: Props) {
  const facts = useStore(entityStore.facts);
  const canEditAttrs = useCanEdit();

  const { entity, attributes, skipLastUpdated } = props;

  const subtitles: (ReactNode | string)[] = [];

  const entityWithAttributes = { ...entity, attributes } as EntityWithAttributes;

  const fact = facts[PersonFact.Location];

  const location = fact?.value;
  if (location) {
    const locationText = location.split(", ").slice(0, 2).join(", ");
    subtitles.push(
      <EditableFact
        factType={PersonFact.Location}
        canEdit={canEditAttrs}
        onEdit={() =>
          entityStore.showFactEditModal.set({
            type: PersonFact.Location,
            currentValue: location,
          })
        }
      >
        Location: {locationText}
      </EditableFact>,
    );
  }

  const birthYear = facts[PersonFact.Birthyear]?.value;
  if (birthYear) {
    const approxAge = ageFromBirthYear(birthYear);
    subtitles.push(
      <EditableFact
        factType={PersonFact.Birthyear}
        canEdit={canEditAttrs}
        onEdit={() =>
          entityStore.showFactEditModal.set({
            type: PersonFact.Birthyear,
            currentValue: `${birthYear}`,
          })
        }
      >
        Approx Age: {approxAge}
      </EditableFact>,
    );
  }

  if (facts[PersonFact.Pronouns]?.value || canEditAttrs) {
    subtitles.push(
      <EditableFact
        factType={PersonFact.Pronouns}
        canEdit={canEditAttrs}
        onEdit={() =>
          entityStore.showFactEditModal.set({
            type: PersonFact.Pronouns,
            currentValue: facts[PersonFact.Pronouns]?.value,
          })
        }
      >
        {editableText("Pronouns", facts[PersonFact.Pronouns]?.value, canEditAttrs)}
      </EditableFact>,
    );
  }

  if (entity.generatedAt && !skipLastUpdated)
    subtitles.push("Last Updated: " + moment(entity.generatedAt).fromNow());

  if (entity.url && !entity.url.includes("linkedin.com/")) {
    subtitles.push(
      <a href={entity.url} className="text-blue-500 hover:text-blue-700">
        {prettyUrl(entity.url)}
      </a>,
    );
  }

  return (
    <div className="text-sm text-gray-400 inline">
      {subtitles.filter(Boolean).map((subtitle, idx) => (
        <Fragment key={idx}>
          {subtitle} {idx != subtitles.length - 1 ? " • " : ""}
        </Fragment>
      ))}
    </div>
  );
}

const editableText = (label: string, value: string | undefined, canEdit: boolean) => {
  if (value) return `${label}: ${value}`;
  else if (canEdit) return `(Set ${label})`;
  else return "";
};
