/* eslint-disable @next/next/no-img-element */
import CompanySubtitle from "@/components/companies/CompanySubtitle";
import ProfilePopoverMenu from "@/components/entities/ProfilePopoverMenu";
import { SectionSummary } from "@/components/entities/ProfileSection";
import SourceTooltip from "@/components/entities/SourceTooltip";
import EntityImageEditModal from "@/components/modals/EntityImageEditModal";
import PersonSubtitle from "@/components/people/PersonSubtitle";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import ExpandoList from "@/components/ui/ExpandoList";
import Loader from "@/components/ui/Loader";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { CompanyFact } from "@/models/facts/facts.types";
import { entityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import {
  EntityType,
  PipelineRunStatus,
  ProfilePageSection,
  ProfileSection,
  ProfileSections,
} from "@/types";
import { useStore } from "@nanostores/react";
import { Entity } from "@prisma/client";
import moment from "moment";
import { AnchorHTMLAttributes, HTMLAttributes, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { twJoin } from "tailwind-merge";

export default withErrorBoundary(function EntityV2Header({
  status,
}: {
  status: PipelineRunStatus;
}) {
  const entity = useStore(entityStore.entity);
  const updateQueue = useStore(entityStore.updateEntityQueue);
  const attributes = useStore(entityStore.attributes);
  const sections = useStore(entityStore.sections);
  const facts = useStore(entityStore.facts);
  const companyBrandedBlurb = facts[CompanyFact.BrandedBlurb]?.value;

  const queueValue = updateQueue[entity.id];
  const isRegenerating = queueValue && !queueValue.resolved;

  const highlights = sections.find((s) => s.section == (ProfileSections.WebLinks as string));
  const entityProps = { entity, attributes };

  const entityDescription =
    (entity.type === EntityType.Company && companyBrandedBlurb) || entity.description;

  return (
    <ProfileBubble className="flex flex-col gap-2" section={ProfilePageSection.Highlights}>
      <div className="flex">
        <div className="flex gap-4 items-center flex-1">
          <ProfileEntityImage entity={entity} />
          <div className="flex-1">
            <div className="flex">
              <h1 className="text-2xl font-semibold flex-1 mr-2">{entity.name}</h1>
              <div className="flex text-gray-400 flex-row gap-1 flex-wrap sm:justify-end items-center">
                <UpdatedAt generatedAt={entity.generatedAt} isRegenerating={isRegenerating} />
                <ProfilePopoverMenu
                  entity={entity}
                  isRegenerating={isRegenerating}
                  status={status}
                />
              </div>
            </div>
            {entity.type === EntityType.Person ?
              <PersonSubtitle skipLastUpdated {...entityProps} />
            : <CompanySubtitle skipLastUpdated {...entityProps} />}
          </div>
        </div>
      </div>

      <div className="text-sm">{entityDescription}</div>

      {highlights && (
        <>
          <hr className="my-2" />
          <div className="flex">
            <h2 className="text-gray-400 text-base">Highlights</h2>
          </div>
          <div className="overview markdown">
            <SectionSummary item={highlights} />
            <Bullets item={highlights} />
          </div>
        </>
      )}
    </ProfileBubble>
  );
});

const UpdatedAt = ({
  generatedAt,
  isRegenerating,
}: {
  generatedAt?: Date | null;
  isRegenerating: boolean;
}) => {
  if (!generatedAt) {
    return null;
  }
  const age = moment(generatedAt).fromNow();
  return (
    <div className="hidden sm:flex flex-row gap-1 flex-wrap sm:justify-end items-center">
      <p>Updated {age}</p>
      {isRegenerating && (
        <>
          <Loader className="mx-2" /> <p>Updating...</p>
        </>
      )}
    </div>
  );
};

function ProfileEntityImage(props: { entity: Entity }) {
  const [open, setOpen] = useState(false);

  const isAdmin = useStore(uiStore.user)?.email?.includes("@distill.fyi");

  return (
    <>
      {isAdmin && <EntityImageEditModal open={open} setOpen={setOpen} />}
      <div
        className={twJoin("h-16 w-16 overflow-hidden c", isAdmin && "cursor-pointer")}
        onClick={isAdmin ? () => setOpen(true) : undefined}
      >
        <EntityIconWithPlaceholder className="h-16 w-16" imageClassName="h-16 w-16" {...props} />
      </div>
    </>
  );
}

function Bullets({ item }: { item: ProfileSection }) {
  const numberedSources = useStore(entityStore.numberedSources);
  const bullets = item.data.bullets;

  if (!bullets) return null;

  return (
    <div className="flex flex-col gap-2">
      <SourceTooltip />
      <ExpandoList
        items={bullets}
        seeMoreClassName="border-t mt-4 pt-4"
        renderItem={(bullet, i) => (
          <div key={i} className="flex flex-row">
            <span className="hidden sm:inline font-bold text-xl text-gray-300 ml-4 mr-8">•</span>
            <ReactMarkdown
              remarkPlugins={[remarkBreaks]}
              components={{
                strong(props: HTMLAttributes<HTMLBaseElement>) {
                  return <span className="font-medium">{props.children}</span>;
                },
                a(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
                  const { title, ...rest } = props;
                  return (
                    <sup>
                      <a {...rest} data-tooltip-id="source-tooltip" data-tooltip-content={title} />
                    </sup>
                  );
                },
              }}
            >
              {bullet.text +
                (bullet.sources
                  ?.map((url, j) => {
                    let idx = numberedSources.indexOf(url) + 1;
                    if (idx == 0) idx = numberedSources.push(url);
                    return `[${idx}](#source${idx} '${url.replace(/'/g, "\\'")}')`;
                  })
                  .join(" ") || "")}
            </ReactMarkdown>
          </div>
        )}
      />
    </div>
  );
}
