import { entityStore } from "@/stores/entityStore";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useStore } from "@nanostores/react";
import { AnchorHTMLAttributes, useState } from "react";
import { usePopper } from "react-popper";
import { Tooltip } from "react-tooltip";

export default function SourceTooltip() {
  return (
    <Tooltip
      id="source-tooltip"
      className="bg-white border rounded z-10"
      opacity={1}
      variant="light"
      place="top"
      clickable
      render={({ content, activeAnchor }) =>
        content ? <SourceTooltipContent href={content} /> : null
      }
    />
  );
}

function SourceTooltipContent({ href }: { href: string }) {
  const sources = useStore(entityStore.sources);
  const source = sources.find((s) => s.url == href);
  return (
    <div className="w-72">
      <b>Source: </b>
      <a href={href} target="_blank" className="text-blue-500" rel="noreferrer">
        {source?.title || href}
        <ArrowTopRightOnSquareIcon className="w-3 h-3 -mt-1 ml-1 inline" />
      </a>
    </div>
  );
}
